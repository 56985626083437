<template>
  <el-dialog
    title="商品限购"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1250px"
    @close="onClose"
    top="5vh"
    class="goods-limit-box"
  >
    <div v-if="dialogVisible" class="flex-column height-full">
      <div class="flex flex-row-center bg-color p-a-10">
        <div class="flex1 flex flex-row-center">
          <span class="theme-border"></span>
          <span>订货的商品有{{ tableAllData.length }}个限购商品</span>
        </div>
        <div>
          <span class="font-warning m-r-10">如果您在当前弹框停留的时间过长，可以刷新当前限购列表的数据后再提交.</span>
          <el-button type="primary" size="mini" @click="onRefresh">刷新</el-button>
        </div>
      </div>
      <el-table
        ref="goodsLimitTable"
        class="table-base m-t-10"
        size="small"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        max-height="400"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed type="selection" align="center" width="55" v-if="!isDetails"> </el-table-column>
        <el-table-column fixed type="index" label="序号" align="center" width="55"> </el-table-column>
        <el-table-column prop="name" label="商品名称" align="center" min-width="250">
          <template slot-scope="scope">
            <div class="flex">
              <el-image
                class="image-div flex flex-center"
                :src="scope.row.images && scope.row.images.length ? scope.row.images[0] : ''"
                :preview-src-list="scope.row.images"
              >
                <img slot="error" class="not-available-img" :src="notAvailableImg" />
              </el-image>
              <div class="flex1 m-l-10">
                <div class="text-left">
                  <span class="text-link text-primary" @click.stop="productsDetails(scope.row)">
                    【{{ scope.row.name }}】 {{ scope.row.code }} {{ scope.row.name }} {{ scope.row.productionCode }}
                    {{ scope.row.category }} {{ scope.row.brand }} {{ scope.row.productionPlace }}
                    {{ scope.row.property }} {{ scope.row.model }} {{ scope.row.vehModel }} {{ scope.row.carSeries }}
                  </span>
                </div>
                <el-tooltip effect="dark" :content="scope.row.goodsSpecification.show" placement="top">
                  <div class="buy-params p-lr-5 m-t-5 font-size-12 font-line-1">
                    {{ scope.row.goodsSpecification.show }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价" align="center" min-width="100">
          <template slot-scope="scope">
            <div>￥{{ scope.row.price }}</div>
            <div class="line-price" v-if="Number(scope.row.linePrice) > 0">￥{{ scope.row.linePrice }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="row_qty" label="订货数" align="center" min-width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.row_qty }}</span>
            <span>{{ scope.row.unit || scope.row.unitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="limit_qty" label="限购数" align="center" min-width="100">
          <template slot-scope="scope">
            <span class="text-danger">{{ scope.row.limit_qty }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="valid_qty" label="可订货数" align="center" min-width="100">
          <template slot-scope="scope">
            <span class="text-danger">{{ scope.row.valid_qty }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <div>
              <div v-if="Number(scope.row.valid_qty) > 0">
                <el-radio v-model="scope.row.limitType" :label="1">继续订购</el-radio>
              </div>
              <div>
                <el-radio v-model="scope.row.limitType" :label="2">取消订购</el-radio>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_footer" v-if="!isDetails">
        <div>
          <el-checkbox v-model="isAllSelected" :disabled="!tableData.length">
            全选
          </el-checkbox>
          <span class="m-lr-8">|</span>
          <span>已选中 {{ selectLen }} 种，{{ selectNum }}件商品</span>
          <span class="m-lr-8">|</span>
          <span class="m-r-8">批量操作</span>
          <el-radio-group v-model="limitAllType" size="mini" :disabled="!selectLen" @click="limitAllTypeClick">
            <el-radio :label="1" border>继续订购</el-radio>
            <el-radio :label="2" border>取消订购</el-radio>
          </el-radio-group>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
        >
        </el-pagination>
      </div>
    </div>

    <span slot="footer">
      <el-button type="primary" plain @click="onClose">取消</el-button>
      <el-button type="primary" @click="onSure">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { goodsLimitInfo } from "@/api/shoppingCart";

export default {
  name: "GoodsLimitBox", // 商品限购
  data() {
    return {
      notAvailableImg: require("@/assets/img/not-available.png"),
      dialogVisible: false,
      loading: false,
      page: 1,
      pageSize: 10,
      tableData: [],
      tableAllData: [],
      selectionData: [],
      isAllSelected: false,
      limitAllType: 0,
      params: {},
      info: {},
      returnFn: null,
      submitInfo: {},
      noLimitGoodsData: [] // 没有限购的商品
    };
  },
  computed: {
    isDetails() {
      return ["productsDetails", "orderList"].includes(this.info.type);
    },
    totalSize() {
      return this.tableAllData.length;
    },
    // 已选商品种类
    selectLen() {
      if (this.isAllSelected) {
        return this.tableAllData.length;
      }
      return this.selectionData.length;
    },
    // 已选商品
    selectNum() {
      let num = 0;
      let newArr = [];
      if (this.isAllSelected) {
        newArr = this.tableAllData;
      } else {
        newArr = this.selectionData;
      }
      newArr.forEach(item => {
        num += Number(item.row_qty);
      });
      return num;
    }
  },
  methods: {
    limitAllTypeClick(val) {
      // console.log("val", val);
      // if ([1, 2].includes(this.limitAllType)) {
      //   this.limitAllType = 0;
      // }
    },
    show(info, rb) {
      this.returnFn = rb;
      let carts = [];
      let skus = [];
      // 商品详情页/多规格 下单
      if (info.type === "productsDetails") {
        skus = [info.data];
      }
      // 购物车页下单
      if (info.type === "shoppingCart") {
        carts = info.data;
      }
      // 订单再次购买
      if (info.type === "orderList") {
        skus = info.data;
      }
      this.info = info;
      // console.log("info", this.info);
      this.params = {
        carts: carts, // 购物车信息
        skus: skus // 查询商品信息
      };
      this.initData();
    },
    initData() {
      const params = {
        ...this.params
      };
      this.loading = true;
      goodsLimitInfo(params)
        .then(res => {
          this.loading = false;
          this.tableAllData =
            res.rows.map(item => {
              return {
                ...item.goods,
                cart_id: item.cart_id,
                goodsSpecification: item.goodsSpecification,
                limit_qty: item.limit_qty,
                row_qty: item.row_qty,
                valid_qty: item.valid_qty,
                limitType: Number(item.valid_qty) > 0 ? 1 : 2
              };
            }) || [];

          if (
            this.info.type === "productsDetails" &&
            this.tableAllData.length &&
            Number(this.tableAllData[0].valid_qty) == 0
          ) {
            this.$message.warning("抱歉，商品已超出限购数量，暂时无法购买！");
            return;
          } else if (this.tableAllData.length) {
            if (this.info.type == "shoppingCart") {
              // 从购物车提交的商品 过滤掉 有限购信息的商品
              this.noLimitGoodsData = this.info.data.filter(
                item1 => !this.tableAllData.some(item2 => item1.id == item2.cart_id)
              );
              // console.log("noLimitGoodsData", this.noLimitGoodsData);
            }
            this.tableDataChange();
            this.dialogVisible = true;
          } else {
            this.returnFn({ type: "ongoing" });
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err?.message || "请求失败");
        });
    },
    onReset() {
      this.page = 1;
      this.pageSize = 10;
      this.tableData = [];
      this.tableAllData = [];
      this.selectionData = [];
      this.limitAllType = 0;
      this.isAllSelected = false;
    },
    onClose() {
      this.dialogVisible = false;
      this.onReset();
    },
    onSure() {
      let select = [];
      // 商品详情、多规格 确定
      if (this.tableData.length == 1 && this.info.type == "productsDetails") {
        select = this.tableData;
        if (select[0].limitType == 1) {
          this.submitInfo = {
            type: this.info.type,
            data: {
              id: select[0].id, // 商品ID
              type: select[0].type, // 商品类型
              qty: select[0].valid_qty, // 数量
              warehouse: select[0].warehouse, // 仓库
              property: select[0].property, // 性质
              productBatch: select[0].productBatch, // 生产批号
              areaDetailId: 0, // 商城区域详情ID，用来实时关联修理厂用
              goodsSpecification: select[0].goodsSpecification // 多规格购买参数
            }
          };
          if (this.info.isSubmitOrder) {
            // 提交订单
            this.returnFn({
              type: "submitOrder",
              orderInfo: {
                skus: [this.submitInfo.data],
                carts: []
              }
            });
          } else {
            this.confirm();
          }
        } else {
          this.onClose();
        }
      } else if (this.tableData.length == 1 && this.info.type == "orderList") {
        // 订单列表、详情 再次购买 确定
        select = this.tableData;
        if (select[0].limitType == 1) {
          this.submitInfo = {
            type: this.info.type,
            data: [
              {
                id: select[0].id, // 商品ID
                type: select[0].type, // 商品类型
                qty: select[0].valid_qty, // 数量
                warehouse: select[0].warehouse, // 仓库
                property: select[0].property, // 性质
                productBatch: select[0].productBatch, // 生产批号
                areaDetailId: 0, // 商城区域详情ID，用来实时关联修理厂用
                goodsSpecification: select[0].goodsSpecification // 多规格购买参数
              }
            ]
          };
          if (this.info.isSubmitOrder) {
            // 提交订单
            this.returnFn({
              type: "submitOrder",
              orderInfo: {
                skus: this.submitInfo.data,
                carts: []
              }
            });
          } else {
            this.confirm();
          }
        } else {
          this.onClose();
        }
      } else if (this.info.type == "shoppingCart") {
        // 购物车 下单 确定
        if (this.isAllSelected) {
          if (this.limitAllType === 0) {
            select = this.tableAllData.filter(item => item.limitType == 1);
          } else if (this.limitAllType === 1) {
            select = this.tableAllData.filter(item => Number(item.valid_qty) > 0);
          } else {
            select = [];
          }
        } else if (this.selectionData.length) {
          if (this.limitAllType === 0) {
            select = this.selectionData.filter(item => item.limitType == 1);
          } else if (this.limitAllType === 1) {
            select = this.selectionData.filter(item => Number(item.valid_qty) > 0);
          } else {
            select = [];
          }
        } else {
          this.$message.warning("请勾选要下单的商品！");
          return;
        }
        if (select.length) {
          const selectList = select.map(item => ({ id: item.cart_id, type: item.type, qty: item.valid_qty }));
          // 选择继续订购的限购商品与没有限购的商品
          const list = [...selectList, ...this.noLimitGoodsData];
          this.submitInfo = {
            type: this.info.type,
            data: list
          };
          if (this.info.isSubmitOrder) {
            // 提交订单
            this.returnFn({
              type: "submitOrder",
              orderInfo: {
                carts: this.submitInfo.data,
                skus: []
              }
            });
          } else {
            this.confirm();
          }
        } else {
          const list = [...this.noLimitGoodsData];
          this.submitInfo = {
            type: this.info.type,
            data: list
          };
          if (list.length) {
            this.confirm();
          } else {
            this.onClose();
          }
        }
      }
    },
    confirm() {
      this.onClose();
      this.returnFn({ type: "close" });
      if (this.$route.path.includes("/order/order-list/order-detail")) {
        this.$router.replace({
          path: "/order/public",
          query: {
            info: encodeURIComponent(JSON.stringify(this.submitInfo)),
            url: "/order/order-list/order-detail"
          }
        });
      } else {
        this.$router.push({
          path: "/order/order-list/order-detail",
          query: {
            info: encodeURIComponent(JSON.stringify(this.submitInfo))
          }
        });
      }
    },
    onRefresh() {
      this.onReset();
      this.initData();
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.page = 1;
      this.tableDataChange();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.tableDataChange();
    },
    tableDataChange() {
      const start = this.pageSize * (this.page - 1);
      const end = this.pageSize * this.page;
      this.tableData = this.tableAllData.slice(start, end);
      this.$forceUpdate();
    },

    // 去商品详情页
    productsDetails(row) {
      this.$router.push({
        path: "/products/details",
        query: {
          data: encodeURIComponent(JSON.stringify(row))
        }
      });
      this.onClose();
    }
  }
};
</script>

<style lang="less" scoped>
@border-color-base: #d9d9d9;

.goods-limit-box {
  /deep/.el-dialog .el-dialog__body {
    background: #f7f7f7;
  }
  .bg-color {
    background: #ecf4fb;
  }
  .theme-border {
    width: 4px;
    height: 20px;
    background: #6589f8;
    margin-right: 10px;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border td {
    border-right: 1px solid @border-color-base;
    border-bottom: 1px solid @border-color-base;
  }

  /deep/ .el-table--border {
    border: 1px solid @border-color-base;
  }
  .image-div {
    width: 80px;
    height: 80px;
    background: #f3f6f9;
    /deep/.el-icon-picture-outline {
      font-size: 24px;
    }
  }
  .buy-params {
    background: #f0f0f0;
    border-radius: 30px;
  }
  /deep/.el-radio {
    font-weight: 400;
    margin-right: 0;
  }
}
</style>
